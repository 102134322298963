<template>
  <div :class="{'border-b border-gray-lightest': border}" class="flex py-4">
    <div class="text-orange mr-2 font-medium text-lg w-1/3 text-left whitespace-nowrap">
      {{`${label}:`}}
    </div>
    <div class="text-lg w-2/3 text-left">
      {{value}}
    </div>
  </div>
</template>

<script>
export default {
  name: "KioskDetailCardItem",
  props: {
    border: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    }
  }
}
</script>
