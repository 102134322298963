<template>
  <div class="w-full md:w-1/2 rounded-md shadow-lg bg-white px-4 py-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "KioskDetailCard"
}
</script>
