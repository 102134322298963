<template>
  <page-template>
    <template #nav>
      <basic-nav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <back-button @click="goBack"></back-button>
          </div>
        </template>
        <template #nav-center>
          <h1 class="text-3xl">Kiosk Details</h1>
        </template>
        <template #nav-right>
          <div class="flex justify-end">
            <img src="@/assets/LogoDarkerText.png" width="200">
          </div>
        </template>
      </basic-nav>
    </template>
    <template #body>
      <div v-if="loading && errorMessage.length === 0" class="flex items-center justify-center">
        <font-awesome-icon size="2x" class="mr-4" icon="spinner" spin color="orange"/>
        <h2 class="text-2xl">Getting kiosk info...</h2>
      </div>
      <div v-else-if="errorMessage.length > 0" class="flex items-center justify-center">
        <h2 class="text-2xl">{{errorMessage}}</h2>
      </div>
      <div v-else class="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-center md:space-x-6">
        <KioskDetailCard>
          <KioskDetailCardItem label="Kiosk Name" :value="kioskDetails.kioskName"/>
          <KioskDetailCardItem label="Stop Location" :value="formatLocation(kioskDetails.stopLocation)"/>
          <KioskDetailCardItem label="In Maintenance" :value="kioskDetails.inMaintenance"/>
          <KioskDetailCardItem label="Serial #" :value="kioskDetails.serialNumber"/>
          <KioskDetailCardItem label="Agency #" :value="kioskDetails.agencyNumber"/>
          <KioskDetailCardItem :border="false" label="Agent #" :value="kioskDetails.agentNumber"/>
        </KioskDetailCard>
        <KioskDetailCard>
          <KioskDetailCardItem label="Kioware Group ID #" :value="kioskDetails.kiowareGroupId"/>
          <KioskDetailCardItem label="Address 1" :value="kioskDetails.address1"/>
          <KioskDetailCardItem label="Address 2" :value="kioskDetails.address2"/>
          <KioskDetailCardItem label="City" :value="formatCity(kioskDetails.cityName)"/>
          <KioskDetailCardItem label="State" :value="kioskDetails.stateAbbreviation"/>
          <KioskDetailCardItem :border="false" label="Zip" :value="kioskDetails.zipCode"/>
        </KioskDetailCard>
      </div>
    </template>
  </page-template>
</template>

<script>
import PageTemplate from "@/components/Helpers/Pages/PageTemplate";
import BasicNav from "@/components/Helpers/Navs/BasicNav";
import BackButton from "@/components/Helpers/Buttons/BackButton";
import {mapActions, mapState} from "vuex";
import KioskDetailCard from "@/components/Cards/KioskDetailCard";
import KioskDetailCardItem from "@/components/Cards/KioskDetailCardItem";

export default {
  name: "VerifoneStatus",
  components: {KioskDetailCardItem, KioskDetailCard, BackButton, BasicNav, PageTemplate},
  data() {
    return {
      loading: true,
      errorMessage: "",
    };
  },
  computed: {
    ...mapState("GeneralSettings", ["kioskDetails"])
  },
  async mounted() {
    try {
      await this.getKioskDetails();
      this.loading = false;
    } catch (e) {
      this.$toasted.error("Failed to fetch kiosk details", {
        position: "top-right",
        duration: 3000
      });
      this.errorMessage = e?.response?.data?.message || "Kiosk details failed to load";
    }
  },
  methods: {
    ...mapActions("GeneralSettings", ["getKioskDetails"]),
    goBack() {
      this.$router.back();
    },
    formatCity(city) {
      return city
          .split(" ")
          .map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`)
          .join("");
    },
    formatLocation(stop) {
      return `${this.formatCity(stop.city)}, ${stop.state}`

    }
  }
}
</script>

<style scoped>

</style>